import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Container,
  Stack,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import DescriptionIcon from "@mui/icons-material/Description";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./theme.ts";
import About from "./pages/About.tsx";
import Projects from "./pages/Projects.tsx";
import Contact from "./pages/Contact.tsx";
import myPhoto from "./assets/myPhoto.jpg";
import myToucan from "./assets/myToucan.jpg";

const Home: React.FC = () => {
  const [showToucan, setShowToucan] = useState(false);

  const handleImageClick = () => {
    setShowToucan((prev) => !prev);
  };

  return (
    <Container sx={{ marginTop: "2rem" }}>
      <img
        src={showToucan ? myToucan : myPhoto}
        alt={showToucan ? "Toucan" : "Mateus Xavier"}
        onClick={handleImageClick}
        style={{
          borderRadius: "50%",
          width: "10rem",
          height: "10rem",
          marginBottom: "1rem",
          cursor: "pointer",
          transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
          opacity: showToucan ? 1 : 0.8,
          transform: showToucan ? "scale(1)" : "scale(0.9)",
        }}
      />
      <Typography variant="h2">Mateus Xavier</Typography>
      <Typography variant="body1">Software Engineer Intern</Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ marginTop: "2rem"}}>
        <Button
          variant="contained"
          color="primary"
          href="https://www.linkedin.com/in/mxs2/"
          target="_blank"
          startIcon={<LinkedInIcon />}
        >
          LinkedIn
        </Button>
        <Button
          variant="contained"
          color="primary"
          href="https://github.com/mxs2"
          target="_blank"
          startIcon={<GitHubIcon />}
        >
          GitHub
        </Button>
        <Button
          variant="contained"
          color="primary"
          href="https://github.com/mxs2/mxs2/blob/main/docs/mxs2.pdf"
          target="_blank"
          startIcon={<DescriptionIcon />}
        >
          Curriculum
        </Button>
      </Stack>
    </Container>
  );
};

const App: React.FC = () => (
  <>
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar>
            <Box
              sx={{ flexGrow: 1, display: "flex", justifyContent: "center"}}
            >
              <Button color="inherit" component={Link} to="/">
                Home
              </Button>
              <Button color="inherit" component={Link} to="/about">
                About
              </Button>
              <Button color="inherit" component={Link} to="/projects">
                Projects
              </Button>
              <Button color="inherit" component={Link} to="/contact">
                Contact
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </>
);

export default App;
