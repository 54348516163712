import React from "react";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";

const Contact: React.FC = () => (
  <Container sx={{ marginTop: "2rem" }}>
    <Typography variant="h2" gutterBottom>
      Email
    </Typography>
    <Typography
      variant="body1"
      sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}
    >
      <Link href="mailto:mateus.xavier@liferay.com">
        mateus.xavier@liferay.com
      </Link>
    </Typography>

    <Typography variant="h2" gutterBottom>
      Where to find me
    </Typography>
    <Box sx={{ mb: 4 }}>
      <iframe
        width="100%"
        height="200px"
        frameBorder="0"
        scrolling="no"
        src="https://maps.google.com/maps?width=100%25&height=200px&hl=en&q=liferay%20latin+(Liferay%20LATAM)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
        style={{ border: 0 }}
        allowFullScreen
      />
    </Box>
  </Container>
);

export default Contact;
