import React from "react";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";

const Projects: React.FC = () => (
  <Container>
    <Typography variant="h2" gutterBottom>
      Projects
    </Typography>
    <List>
      <ListItem>
        <ListItemText
          primary={
            <>
              <Link
                href="https://github.com/mxs2/monitoria-fp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Monitoria, Fundamentos de Programação
              </Link>
              : Repository with lessons.
            </>
          }
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <>
              <Link
                href="https://github.com/mxs2/atendamais"
                target="_blank"
                rel="noopener noreferrer"
              >
                AtendaMais
              </Link>
              : Java Vaadin based system, with Maven and SpringBoot.
            </>
          }
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <>
              <Link
                href="https://github.com/mxs2/unicompare"
                target="_blank"
                rel="noopener noreferrer"
              >
                UniCompare
              </Link>
              : Python CRUD that compares and ranks university prices.
            </>
          }
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
    </List>
  </Container>
);

export default Projects;
