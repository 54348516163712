import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";

const About: React.FC = () => (
  <Container>
    <Typography variant="h2" gutterBottom>
      Education
    </Typography>
    <List>
      <ListItem>
        <ListItemText
          primary="A.D. in Systems Analysis and Development at CESAR School, 2024.2 Class"
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary="Tech High School Degree, Escola Técnica Estadual Porto Digital, 2019 - 2021"
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
    </List>

    <Divider style={{ margin: "1rem 0" }} />

    <Typography variant="h2" gutterBottom>
      Experience
    </Typography>
    <List>
      <ListItem>
        <ListItemText
          primary={
            <>
              * Software Engineer Intern,{" "}
              <Link
                href="https://www.liferay.com/pt/"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                Liferay
              </Link>
              .
            </>
          }
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <>
              Developer Intern,{" "}
              <Link
                href="https://voxarlabs.cin.ufpe.br/"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                Voxar Labs
              </Link>
              .
            </>
          }
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          primary={
            <>
              Computer Vision Consultant,{" "}
              <Link
                href="https://xpenergy.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                XP Energy
              </Link>
              .
            </>
          }
          sx={{ display: "flex", justifyContent: "space-between" }}
        />
      </ListItem>
    </List>
  </Container>
);

export default About;
