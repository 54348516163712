import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#121212",
      light: "#1e1e1e",
      dark: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#757575",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "#ffffff",
      secondary: "#b0b0b0",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "2.5rem",
      lineHeight: 1.2,
      textAlign: "center",
      color: "#ffffff",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.3,
      textAlign: "center",
      color: "#ffffff",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.5,
      color: "#b0b0b0",
      textAlign: "justify",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: "2rem",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "justify",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          padding: "10px 20px",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#e93434",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#e93434",
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
});

export default theme;
